<template>
  <div>
    <b-container fluid :key="getItemCache.length">
      <div v-if="getShow" class="container">
        <div class="top-bar">
          <span
            ><h4>{{ $t("filterCard.headline") }}</h4></span
          >
          <div
            class="exitCross"
            @click="showFilter(), setCurrentFilter(), setSelectedType()"
          >
            <b-icon-x-circle class="h2" color="black"></b-icon-x-circle>
          </div>
        </div>

        <div class="mid-bar">
          <div class="filterArea">
            <div class="buttons">
              <b-row no-gutters>
                <b-col cols="4" class="buttonContainer">
                  <span
                    title="Tegn et eget område. Trykk på knappen, deretter trykk på kartet. Trykk på knappen igjen for å skru av."
                  >
                    <b-button class="iconContainer">
                      <b-icon-pencil
                        class="icon rounded-circle p-1"
                        @click="setShowDraw"
                      ></b-icon-pencil>
                    </b-button>
                  </span>
                </b-col>
                <b-col cols="4" class="buttonContainer">
                  <span
                    title="Vis en liste over alle kontainere på kartet, eller i et tegnet område."
                  >
                    <b-button class="iconContainer">
                      <b-icon-card-list
                        class="icon rounded-circle p-1"
                        @click="setShowList"
                      ></b-icon-card-list>
                    </b-button>
                  </span>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="filterArea">
            <b-row no-gutters class="categoryRow"
              ><h5>{{ $t("type") }}</h5></b-row
            >
            <b-row no-gutters
              ><b-col
                cols="4"
                class="buttonContainer"
                v-for="(item, index) of equipmentTypes"
                :key="index"
                @click="setSelectedType(item.value)"
              >
                <b-button variant="dark" class="button" pill>{{
                  $t(`${item.value}`)
                }}</b-button></b-col
              >
              <b-col
                cols="4"
                class="buttonContainer"
                @click="setSelectedType('sensor')"
              >
                <b-button variant="dark" class="button" pill>{{
                  $t("volume")
                }}</b-button></b-col
              ></b-row
            >
          </div>
         <div class="filterArea">
            <b-row no-gutters class="categoryRow"
              ><h5>{{$t('filterCard.regions')}}</h5></b-row
            >
            <b-row no-gutters
              ><b-col
                cols="4"
                class="buttonContainer"
                v-for="(item, index) of regions"
                :key="index"
                @click="setSelectedRegion(item)"
              >
                <b-button variant="dark" class="button" pill>{{
                  item
                }}</b-button></b-col
              >
              </b-row
            >
          </div>
          <div
            class="filterArea"
            v-for="(type, index) in availableFilters"
            :key="index"
          >
            <b-row no-gutters class="categoryRow">
              <h5>{{ $t(`${index}`) }}</h5>
            </b-row>
            <b-row no-gutters>
              <b-col
                cols="4"
                class="buttonContainer"
                v-for="(item, i) in type"
                :key="i"
                @click="setCurrentFilter({ index, item })"
              >
                <b-button variant="dark" pill class="button">{{
                  addString(item, index)
                }}</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
// import Icon from "@/components/icons/Icons.vue";
import { mapGetters, mapActions } from "vuex";
import { getRegions, getOrganizations } from "@/services/reenapi.js";

export default {
  name: "FilterCard",
  components: {
    // Icon,
  },
  data() {
    return {
      closeFilter: false,

      // if more filters is to be shown, update this with new filter type, and update the vuex store
      equipmentTypes: [
        {
          key: "Kjøretøy",
          value: "vehicles",
        },
        { key: "Container", value: "containers" },
      ],
      regions: [],
      availableFilters: {
        volume: [],
        weight: [],
        name: [],
        mount_type: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getShow",
      "getCurrentTheme",
      "selectedMarker",
      "getItemCache",
      "getDict",
    ]),
  },
  methods: {
    ...mapActions([
      "showFilter",
      "setCurrentFilter",
      "setSelectedType",
      "setShowDraw",
      "setShowList",
      "setSelectedRegion"
    ]),
    // Uses the existing data from the API to create the filter options
    createFilterOptions(data, filters) {
      for (var type in filters) {
        for (var item in data) {
          if (data[item].container_type) {
            var currentItem = data[item].container_type[type];
            var checkData = filters[type].filter(
              (data) => data === currentItem
            );
            if (checkData.length == 0) {
              this.availableFilters[type].push(currentItem);
            }
          }
        }
      }
    },
    addString(item, type) {
      for (var dictType in this.getDict) {
        if (type == dictType) {
          return item + this.getDict[dictType];
        }
      }
    },
  },
  updated() {
    this.createFilterOptions(
      this.getItemCache.containers,
      this.availableFilters
    );
  },
  async mounted() {
    let organization = await getOrganizations();
    let data = [];
    for (let org of organization.data) {
      let orgData = await getRegions(org.id);
      for (let orgRegion of orgData.data) data.push(orgRegion.name);
    }
    this.regions = data;
    this.regions = new Set(this.regions)
  },
};
</script>

<style scoped>
.categoryRow {
  border-bottom: 1px solid black;
  margin-bottom: 5px;
}
.top-bar {
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 3000;
  padding: 0;
  padding-top: 25px;
  background-color: white;
}
.container {
  padding-bottom: 25px;
  background-color: white;
}
.icon {
  cursor: pointer;
  height: 40px;
  width: 40px;
}

.iconContainer {
  border-radius: 50%;
  padding: 2px;
  height: 44px;
  width: 44px;
  background-color: black;
}
.container {
  color: white;
  padding: 25px;
  padding-top: 0;
}
.buttonContainer {
  min-width: 100px;
  text-align: center;
}
.button {
  border-radius: 25px;
  margin: 3px 3px 3px 3px;
  min-width: 100px;
}
.mid-bar {
  order: 1;
  gap: 10px;
}
.filterArea {
  padding-top: 10px;
}

.filterArea:last-child {
  border: none;
  gap: 7%;
}

@media (min-width: 992px) {
  .button {
    min-width: 90px;
  }
}
</style>